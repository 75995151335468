import {FORMATTER_FIELD, InputErrorFormatter} from './InputErrorFormatter';

const CONTAINER_ELEMENT = "<div class='errorMessages font-thin text-danger f-s-8'></div>";

export class InputErrorInforming {

    private container     : ng.IAugmentedJQuery;


  static $inject = ['containerName', 'element', 'attrs', 'ctrl'];
    constructor( private containerName : string | ng.IAugmentedJQuery,
                 private element       : ng.IAugmentedJQuery,
                 public attrs          : ng.IAttributes,
                 private ctrl          : ng.INgModelController) {
        InputErrorFormatter.attachToController(ctrl);
    }

    public checkErrors( errors: Object ) {

        if ( !(<InputErrorFormatter>this.ctrl[FORMATTER_FIELD]).hasErrors() ) {
            this.cleanErrors();
            return;
        }

        this.addErrors( (<InputErrorFormatter>this.ctrl[FORMATTER_FIELD]).errorMessages );

    }

    private getContainer() : ng.IAugmentedJQuery {

        if ( this.container )
            return this.container;

        switch (true) {

            case !this.containerName:
                    this.container = this.element.after( CONTAINER_ELEMENT )
                                                 .next()
                                                 .addClass('text-right');
                break;

            case typeof this.containerName === "string":
                this.container = <ng.IAugmentedJQuery>window['angular'].element(this.containerName);
                break;

            case this.containerName && !!this.containerName.length:
                this.container =  <ng.IAugmentedJQuery>window['angular'].element(CONTAINER_ELEMENT);
                (<ng.IAugmentedJQuery>this.containerName).append(this.container);
                break;

            default:
                this.container = undefined;
        }

        return this.container;
    }

    private addErrors( errors : Array<string> ) {
        this.getContainer() &&
            this.getContainer()
                .empty()
                .append(errors.map(error => `<span class="error clearfix">${error}</span>`));

    }

    private cleanErrors() {
        this.getContainer() && this.getContainer().empty();
    }

    static factory(): ng.IDirectiveFactory {
        return  () => ({
            restrict    :'A',
            require     : 'ngModel',
            scope       : false , /*
                {
                inputErrorInforming: '=',
                inputErrorInformingCtrl: '='
            }, */
        //    controller  :  InputErrorInforming,

            link(scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: ng.INgModelController ) {

                let obj = new InputErrorInforming(scope.$eval(attrs.inputErrorInforming), element, attrs, scope.$eval(attrs.inputErrorInformingCtrl) || ctrl);

                scope.$watch(() => ctrl.$viewValue, () => setTimeout( obj.checkErrors.bind(obj), 0) , true);
                scope.$on('$destroy', () => { obj = null; } );

            }
        })
    }

}

window['angular'].module('loya.builder').directive('inputErrorInforming', InputErrorInforming.factory() );
