
enum applyingValues {
    'on'  = 'byCard',
    'off' = 'auto',
}

enum cardTypeValues {
    'code'   = 'code',
    'prefix' = 'prefix',
}

const useField   = 'applying';
const typeField  = 'cardType';
const valueField = 'cardValue';

export class OfflineApplying {

    // Передаваемые параметры в компонент
    public model : {
        applying   : applyingValues;
        cardType  ?: cardTypeValues;
        cardValue ?: string;
    };

    public configOnly: string;

    public cardTypeLabels = {

        [ cardTypeValues.code   ]: 'Код карты',
        [ cardTypeValues.prefix ]: 'Префикс карты'

    }

    get use() {
        return this.model[useField];
    }

    set use( value: applyingValues ) {
        this.model[useField] = value;

        if ( !this.type ) {
            this.type = cardTypeValues.code;
        }

        this.checkVisible();
        this.clearFields();
    }


    get type() {
        return this.model[typeField];
    }

    set type( value: cardTypeValues ) {
        this.model[typeField] = value;
    }


    get value() {
        return this.model[valueField];
    }

    set value( value: string ) {
        this.model[valueField] = value;
    }


    get isEnabled() {
        return this.use === applyingValues.on;
    }

    static $inject = ['$scope', '$element'];

    constructor( public $scope: any, public $element: any ) { }

    public $onInit() {

        // Для вжух кнопки установим __proto__
        this.$scope.mechanic           = this.$scope.$parent.mechanic;
        this.$scope.unconfiguredFields = this.$scope.$parent.unconfiguredFields;
      //  Object.setPrototypeOf(this.$scope, this.$scope.$parent);
     //   Проверка на существование фильтров в списке
     //   $scope.$watch( ( _: any ) => this.model && this.model.$search, this.checkIsEnabled.bind(this) );

        this.checkVisible();
        this.setDefaults();

    }

    private setDefaults() {

        // Установка использования по умолчанию
        if ( !this.use ) {
            this.use = applyingValues.off;
        }

        // Установка типа карты по умолчанию
        if ( this.use === applyingValues.on ) {
            this.type = this.type || cardTypeValues.code;
        }

    }

    public  isRequired = true;
    private offWatchVisible: Function;
    private checkVisible() {

        if ( this.offWatchVisible ) {
            this.offWatchVisible();
        }

        if ( this.use === applyingValues.on ) {

            this.isRequired = true ;
            setTimeout(() => {
                const input = this.$element.find('input[ng-model="$ctrl.value"]');
                this.offWatchVisible = this.$scope.$watch(() => input.is(':not(:hidden)'), (val: boolean) => {
                    this.isRequired = val;
                });
            });

        } else {

            this.offWatchVisible = undefined;
            this.isRequired = true ;

        }

    }

    // очистка лишних полей в Json
    private clearFields() {

        if ( this.use === applyingValues.off ) {
            delete this.model[typeField]  ;
            delete this.model[valueField] ;
        }

    }
};
