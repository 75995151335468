import {pathToRealPath} from "./modelRealPath";

export const InputComponentBindongs = {
    "model"       : "=",
    "placeholder" : "<",
    "required"    : "<",
    "readonly"    : "<",
    "maxlength"   : "<",
    "minlength"   : "<",
    "max"         : "<",
    "min"         : "<",
    "pattern"     : "@",
    "errors"      : "<",

    "settings"    : "<",

    "onChange"    : "&"
};

export const InputComponentTransclude = {
    'prefixSlot': '?prefix',
    'postfixSlot': '?postfix'
};

const IS_UNDEFINED = ( val : any) => typeof val === "undefined";


export class InputComponentClass {

    public model       : string;
    public placeholder : string;
    public required    : boolean;
    public readonly    : boolean;
    public maxlength   : number;
    public minlength   : number;
    public max         : number;
    public min         : number;
    public pattern     : RegExp | string ;
    public settings    : any;

    public errors      : Array<string>;

    public onChange    : Function;

    public prefixSlot : any;
    public postfixSlot : any;

    public readableModelPath : string = '';

  static $inject = ['$transclude','$element', '$scope' ];

    constructor( private $transclude: ng.ITranscludeFunction, public $element: ng.IRootElementService, $scope : ng.IScope) {
        this.inherit_wrapper('$onInit');
        this.getReadableModel($scope);
    }

    private inherit_wrapper(id: string) {

        let savedMethod = this[id];

        this[id] = function () {

            InputComponentClass.prototype[id].call(this, arguments);
            return savedMethod.call(this, arguments);
        }

    }

    public $onInit() {

        if (this.settings) {
            this.placeholder = IS_UNDEFINED(this.placeholder ) ? this.settings.placeholder : this.placeholder ;
            this.required    = IS_UNDEFINED(this.required  )   ? this.settings.required : this.required  ;
            this.readonly    = IS_UNDEFINED(this.readonly  )   ? this.settings.readonly : this.readonly  ;
            this.maxlength   = IS_UNDEFINED(this.maxlength )   ? this.settings.maxlength || this.settings.maxLength : this.maxlength ;
            this.minlength   = IS_UNDEFINED(this.minlength )   ? this.settings.minlength || this.settings.minLength : this.minlength ;
            this.pattern     = IS_UNDEFINED(this.pattern )     ? this.settings.pattern : this.pattern ;
            this.max         = IS_UNDEFINED(this.max )         ? this.settings.max || this.settings.max : this.max ;
            this.min         = IS_UNDEFINED(this.min )         ? this.settings.min || this.settings.min : this.min ;
        }

        if (this.hasPostfix() || this.hasPrefix()) {
            this.setElementAsInputGroup();
        }
    }

    public validateErrors = () => {
        return !(Array.isArray( this.errors ) && this.errors.length >0);
    }

    public formateErrors = () => {
        return this.errors;
    }

    public hasPrefix() {
        return  this.$transclude &&
                this.$transclude.isSlotFilled &&
                this.$transclude.isSlotFilled('prefixSlot');
    }

    public hasPostfix() {
        return  this.$transclude &&
                this.$transclude.isSlotFilled &&
                this.$transclude.isSlotFilled('postfixSlot');
    }

    public elementForGroup() {
        return this.$element;
    }

    public setElementAsInputGroup() {
        this.elementForGroup().addClass('input-group');
    }

    public getReadableModel( $scope : ng.IScope ) {

        let modelString = this.$element.attr( 'model' );
        this.readableModelPath = pathToRealPath(modelString, $scope.$parent);

    }

}
