export function pathToRealPath( modelString : string , $scope : ng.IScope ) {

    if ( !modelString ) return;

    if ( modelString.indexOf('[' ) < 0 ) {
     //   this.readableModelPath = modelString;
        return;
    }

    try {
        return modelString.replace(/\[.*\]/ig, ( subId: string ) => {
            return '.' + $scope.$eval( subId.slice(1, subId.length - 1) ) ;
        });

    } catch (e) {
        return modelString;
    }
}