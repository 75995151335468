import {IComponentBindings} from "./helpers/IComponentBindings";
import {InputComponentBindongs, InputComponentClass} from "./helpers/InputComponentClass";
import './helpers/InputErrorInforming';

interface ISelectOptions {
    id: string | number,
    label: string
}

class InputController extends InputComponentClass implements IComponentBindings {

    public model: any;
    private previousModel: any;

    private errorContainer: ng.IAugmentedJQuery;
    private result: ISelectOptions | Array<ISelectOptions>;
    private options: Array<ISelectOptions>;
    private multiple: boolean;

  static $inject = ['$element', '$scope', '$transclude'];

    constructor($element: ng.IRootElementService, $scope: ng.IScope, $transclude: ng.ITranscludeFunction) {
        super($transclude, $element, $scope);

    }

    public $onInit() {
        this.errorContainer = this.$element.find('span.error_container');
        this.selectById(<any>this.model);

    }

    public onScopeChange(newValue: any) {

        if (!newValue) {
            return
        }

        if (!this.multiple) {
            this.model = (<any>newValue).id;
            this.previousModel = this.model;
        } else {
            this.model = (<any>this.result).map((i: any) => i.id);
            this.previousModel = this.model.slice();
        }

        if (this.onChange) {
            setTimeout(() => this.onChange({value: this.model}));
        }
    }

    public selectById(id: number | Array<number>) {

        this.previousModel = Array.isArray(id) ? id.slice() : id;

        if (!Array.isArray(this.options))
            return;

        if (!this.multiple) {
            this.result = this.options.find(i => i.id === id);
        } else {
            id = !Array.isArray(id) ? [id] : id;
            this.result = this.options.filter(i => ((<Array<number>>id).indexOf(<any>i.id) >= 0));
        }

    }

    public $onChanges(value: any) {

        if (value.options && value.options.currentValue) {
            this.selectById(this.model);
        }

    }

    public $doCheck() {

        if ( typeof this.model === "undefined" && typeof this.previousModel!== "undefined") {
            this.previousModel = undefined;
            this.result = undefined;
            return;
        }

        if (!Array.isArray(this.model) && this.previousModel !== this.model) {
            this.selectById(this.model);
            return;
        }

        if (Array.isArray(this.model) && Array.isArray(this.previousModel) &&
            ( this.previousModel.length !== this.model.length || this.previousModel.some( i => this.model.indexOf(i) <0 ) ) ) {
            this.selectById(this.model);
            return;
        }

    }
}



export default window['angular'].module('loya.builder').component('customInputSelect', {
    template    : `<input type="hidden" ng-if="$ctrl.required" ng-model="$ctrl.result"/>
                   <span ng-if="::!$ctrl.multiple">
                       <ui-select
                            ng-model="$ctrl.result"
                            ng-class="{ 'b b-danger r': $ctrl.required && !$ctrl.result }"
                            theme="bootstrap"
                            on-select = "$ctrl.onScopeChange($item)"
                            on-remove = "$ctrl.onScopeChange($item)"
                            ng-disabled = "$ctrl.readonly"
                            custom-input-model-id = "{{::$ctrl.readableModelPath }}"
                       >
                        <ui-select-match
                            class="text-ellipsis"
                            placeholder="{{ $ctrl.placeholder }}">
                                <span class="font-thin" ng-bind="$select.selected.label"></span>
                        </ui-select-match>

                        <ui-select-choices group-by="'group'"  repeat="item in $ctrl.options | filter: $select.search">
                            <span ng-bind-html="item.label | highlight: $select.search"></span>
                        </ui-select-choices>

                    </ui-select>
                </span>
                <span ng-if="::$ctrl.multiple">
                       <ui-select
                            ng-model="$ctrl.result"
                            ng-class="{ 'b b-danger r': $ctrl.required && ( !$ctrl.result || $ctrl.result.length === 0 ) }"
                            theme="bootstrap"
                            on-select = "$ctrl.onScopeChange($item)"
                            on-remove = "$ctrl.onScopeChange($item)"
                            ng-disabled ="$ctrl.readonly"
                            custom-input-model-id = "{{::$ctrl.readableModelPath }}"
                            multiple
                       >
                        <ui-select-match
                            class="text-ellipsis"
                            placeholder="{{ $ctrl.placeholder }}">
                                <span class="font-thin" ng-bind="$item.label"></span>
                        </ui-select-match>

                        <ui-select-choices group-by="'group'" repeat="item in $ctrl.options | filter: $select.search">
                            <span ng-bind-html="item.label | highlight: $select.search" data-testid="item in group"></span>
                        </ui-select-choices>

                    </ui-select>
                </span>
                <span class="error_container"></span>
`,
    controller  : InputController,
    bindings    : Object.assign({},InputComponentBindongs, {
        options  : '<',
        multiple : '<'
    })

});

